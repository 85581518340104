/*
*   // = components/nameFile.js
*/

/* =====================================================
*
*  ОТКРЫТИЯ МЕНЮ ПО КЛИКУ НА БУРГЕР НА МОБИЛЬНЫХ ВЕРСИЯХ
*
* ==================================================== */

try {

  /* ============ Переменные =============*/

  const burger = document.querySelector(".js-burger");

  /* ============ События =============*/

  // Мыши
  burger.addEventListener("click",function (evt) {

    evt.preventDefault();

    this.classList.toggle("header__burger--close");

    this.nextElementSibling.classList.toggle("header__menu--active");

    let textBurger = this.firstElementChild.innerText;

    if(textBurger === "Открыть меню"){

      this.firstElementChild.innerText = "Закрыть меню"

    }else{

      this.firstElementChild.innerText = "Открыть меню"

    }

    document.querySelector(".page__body").classList.toggle("page__body--overflow");

  });

}
catch(err){
  console.info("Burger: " + err.message);
}
/* ================================================================================ */
/* ======================== ПОДГРУЗКУ СТАРЫХ НОВОСТЕЙ ============================= */
/* ================================================================================ */

try {

// Сколько показывать элементов При загрузки страницы
  const numToShowDefault = 5;

// Сколько показывать элементов При нажатии по кнопке "Показать еще"
  const numToShow = 5;

// Отбираем в набор все элементы которые будут показывать по клику по кнопки "Показать еще"
  let listPortfolio = Array.from(document.querySelectorAll(".js-news__item"));

// Считаем количество элементов
  let numInList = listPortfolio.length;

// Отбираем кнопку показать еще
  let buttonMore = document.querySelector(".js-news__button");

// Если общиее количество элементов больше чем сколько нужно показывать по умолчанию.
  if (numInList > numToShowDefault) {

    // показываем блок показать еще
    buttonMore.classList.add("news__button--show");

  }

// показываем первые N элементов
  listPortfolio.slice(0, numToShowDefault).forEach(function (element) {
    element.classList.add("news__item--show");

    setTimeout(function () {
      element.classList.add("news__item--effect");
    }, 300);
  });

  /* при нажатии на кнопку показать еще */
  buttonMore.addEventListener("click", function (evt) {

    // отменяем действия по умолчанию
    evt.preventDefault();

    // находим все элементы которые видимые
    let showing = document.querySelectorAll(".news__item--show").length;

    // с какого элемента и по какой элемент ( не включительно ) показать
    listPortfolio.slice(showing, showing + numToShow).forEach(function (element) {

      element.classList.add("news__item--show");

      setTimeout(function () {
        element.classList.add("news__item--effect");
      }, 300);

    });

    // снова находим все элементы которые видимые
    let nowShowing = document.querySelectorAll(".news__item--show").length;

    // если видимых элементов больше или равно всему количеству элементов.
    if (nowShowing >= numInList) {

      // скрываем блок показать еще
      buttonMore.classList.remove("news__button--show");
    }
  });

}catch(err){
  console.info("ShowMoreArticle: " + err.message);
}
/* ============================
*
*  РАЗВЕРТЫВАНИЯ ПОЛНОЙ НОВОСТИ
*
* =========================== */


/* ============ Переменные =============*/

const full_article = document.querySelectorAll(".js-full_article");

/* ============ События =============*/

// проходимся в цикле по full_article
full_article.forEach(function (element) {

  // Вешаем события клика на каждый элемент
  element.addEventListener("click",function (e) {

    // отменяем действие по умолчанию у элемента
    e.preventDefault();

    // находим прородителя элемента
    let parent = this.parentNode.parentNode;
    // находим блок заголовка
    let article__title = parent.querySelector(".article__title");
    // находим блок описания
    let article__content = parent.querySelector(".article__content");

    // ширина окна (без учета полосы прокрутки)
    let viewportScreen  = document.documentElement.clientWidth;

    // если текст элемента по которомы произашел клик равен "подробнее"
    if(this.innerText === "подробнее"){

      parent.classList.toggle("article--toggle");

      // добывляем свойство overflow = "visible" если ширина экрана больше или равна 576
      if(viewportScreen >= 576){
        parent.classList.add("article--active");

        setTimeout(()=>{

          article__content.style.overflow = "visible";

          article__title.style.height = article__title.scrollHeight + "px";
          article__content.style.height = article__content.scrollHeight + "px";

          this.innerText = "закрыть";
        },150);

      }else {

        article__title.style.height = article__title.scrollHeight + "px";
        article__content.style.height = article__content.scrollHeight + "px";

        this.innerText = "закрыть";
      }
    }
    // если текст элемента по которомы произашел клик не равен "подробнее"
    else{

      parent.classList.toggle("article--toggle");

      // проверяем существует ли у него класс "article--active"
      if(parent.classList.contains("article--active")){

        parent.classList.remove("article--active");
        parent.classList.remove("article--padding");

      }

      article__title.removeAttribute("style");
      article__content.removeAttribute("style");

      this.innerText = "подробнее";
    }

  })
});
/* ==============
*
*  МОДАЛЬНЫЕ ОКНА
*
* ============= */

try {

  /* ============ Переменные =============*/

  // узлы отвечающие за закрытия модальных окон
  const closeModal = document.querySelectorAll(".js-close");

  // узлы отвечающие за открытия окна регистрации
  const regTrainer = document.querySelectorAll(".js-reg");

  // узлы отвечающие за открытия окна входа
  const enterButton = document.querySelectorAll(".js-enterBtn");

  // узлы отвечающие за открытия восстоновить пароль
  const recoveryButton = document.querySelectorAll(".js-rec");

  // переменная которая будет сохранять элемент который вызвал модальное окно
  let lastFocus;

  // переменная в которая отвечает на вопрос "открыто ли модальное окно"
  let modalOpen = false;

  // переменная в которой хранится открытое модальное окно
  let modalActive;


  /* ============ Функции =============*/

  // Функция 1: сохраняет элемент который вызвал модальное окно в переменую lastFocus
  function modalShow (modal) {

    // в переменую lastFocus покадет элемент который вызвал модальное окно
    lastFocus = document.activeElement;

    // делаем модальное окно интерактивным с помощью атрибута tabindex
    modal.querySelector(".modal__inner").setAttribute('tabindex', '0');

    // через 200 милисекунд даем модальному окну фокус
    setTimeout(()=>{

      modal.querySelector(".modal__inner").focus();

    },200)
  }

  // Функция 2: Возвращает сохраненому в переменой lastFocus элементу фокус после закрытия модального окна
  function modalClose () {

    // возвращаем фокус
    lastFocus.focus();

  }

  // Функция 3: Зацикливаем интерактивные элемента активного модального окна
  function focusRestrict ( event ) {

    // отлавливаем события фокус на перехват
    document.addEventListener('focus', function( event ) {

      // если модальное окно открыто и элемент который вызвал фокус не из активного модального окна
      if ( modalOpen && !modalActive.contains( event.target ) ) {

        // отменяем распростронения события
        event.stopPropagation();

        // ставим фокус в начало активного модельного окна
        modalActive.querySelector(".modal__inner").focus();

      }
    }, true);
  }


  /* ============ При загрузке страницы =============*/

  // проверяем есть ли класс modal--active в документе, если есть то есть активное модальное окно
  if(document.querySelector(".modal--active")){

    // кладем активное модальное окно в переменую modalActive
    modalActive = document.querySelector(".modal--active");

    // щелкаем переключатель в значения истина
    modalOpen = true;

    // фызываем функцию
    modalShow(modalActive);
  }

  // фызываем функцию
  focusRestrict();


  /* ============ События =============*/


  // - Мыши - //

  // События 1: Показать модальное окно регистрации
  regTrainer.forEach(function (element){

    // если произошел клик
    element.addEventListener("click",function (evt) {

      // отменяем событие по умолчанию
      evt.preventDefault();

      // проверяем существует ли у него класс "modal--active"
      if(document.querySelector(".js-enter").classList.contains("modal--active")){

        // если существует удаляем
        document.querySelector(".js-enter").classList.remove("modal--active");
      }

      // отбираем в набор модальное окно регистрации
      modalActive = document.querySelector(".js-registration");

      // показываем модальное окно регистрации
      modalActive.classList.add("modal--active");

      // вызываем функцию modalShow и передаем ей модальное окно;
      modalShow(modalActive);

      modalOpen = true;

      // скрываем контент который не помещается в body (в экран)
      document.querySelector(".page__body").classList.add("page__body--overflow");

    });
  });

  // События 2: Показать модальное окно входа
  enterButton.forEach(function (element){

    // если произошел клик
    element.addEventListener("click",function (evt) {

      // отменяем событие по умолчанию
      evt.preventDefault();

      // проверяем существует ли у него класс "header__burger--close"
      if(document.querySelector(".js-burger").classList.contains("header__burger--close")){

        // если существует удаляем
        document.querySelector(".js-burger").classList.remove("header__burger--close");
        document.querySelector(".js-menu").classList.remove("header__menu--active");
      }

      // отбираем в набор модальное окно регистрации
      modalActive = document.querySelector(".js-enter");

      // показываем модальное окно регистрации
      modalActive.classList.add("modal--active");

      // вызываем функцию modalShow и передаем ей модальное окно;
      modalShow(modalActive);

      modalOpen = true;

      // скрываем контент который не помещается в body (в экран)
      document.querySelector(".page__body").classList.add("page__body--overflow");

    });
  });

  // События 3: Показать модальное окно восстоновления пароля
  recoveryButton.forEach(function (element){

    // если произошел клик
    element.addEventListener("click",function (evt) {

      // отменяем событие по умолчанию
      evt.preventDefault();

      // проверяем существует ли у него класс "modal--active"
      if(document.querySelector(".js-enter").classList.contains("modal--active")){

        // если существует удаляем
        document.querySelector(".js-enter").classList.remove("modal--active");
      }

      // отбираем в набор модальное окно регистрации
      modalActive = document.querySelector(".js-recovery");

      // показываем модальное окно регистрации
      modalActive.classList.add("modal--active");

      // вызываем функцию modalShow и передаем ей модальное окно;
      modalShow(modalActive);

      modalOpen = true;

      // скрываем контент который не помещается в body (в экран)
      document.querySelector(".page__body").classList.add("page__body--overflow");

    });
  });

  // События 4: Скрыть модальное окно регистрации | входа | востоновления пароля
  closeModal.forEach(function (element){

    // если произошел клик
    element.addEventListener("click",function (evt) {

      // отменяем событие по умолчанию
      evt.preventDefault();

      // находим родителя
      const parent = this.closest(".modal");

      // проверяем существует ли у него класс "modal--active"
      if(parent.classList.contains("modal--active")){

        // если существует удаляем
        parent.classList.remove("modal--active");
      }

      modalOpen = false;

      // вызываем функцию modalClose
      modalClose();

      // удаляем класс page__body--overflow
      document.querySelector(".page__body").classList.remove("page__body--overflow");

    });
  });

  // - Клавиатуры - //

  // слушаем события с клавиатуры
  window.addEventListener("keydown", function (evt){

    // если нажата клавиша Escape
    if(evt.key === "Escape" || evt.keyCode === 27) {

      // отбираем в набор все узлы с классо modal--active и проходимся по ним в цикле
      document.querySelectorAll(".modal--active").forEach((element)=>{

        // если есть класс modal--active
        if(element.classList.contains("modal--active")){

          // отменяем событие по умолчанию
          evt.preventDefault();

          // удаляем
          element.classList.remove("modal--active");

          modalOpen = false;

          // вызываем функцию modalClose
          modalClose();

          // удаляем класс page__body--overflow
          document.querySelector(".page__body").classList.remove("page__body--overflow");

        }

      });

    }

  });

}
catch(err){
  console.info("Modal: " + err.message);
}
/* ===============
*
*  ВАЛИДАЦИЯ ФОРМ
*
* ============= */

try {

  /* ============ Переменные ============= */

  // отбираем все формы со страницы
  const listForms = document.querySelectorAll(".js-form");


  /* ============ Функции =============*/

  // валидирует на проверку длину строки | проверка спецсимволов | проверка на правильный емаил | совпадений паролей
  function validation(element, title, min = 3, max = 20,array = null) {

    // пустая переменная
    let error = '';

    /* 1 Проверка на длину строки : проверяем есть ли у элемента атрибут data-length */
    if (element.dataset.length){

      // проверка на минимальное количество символов
      if (element.value.trim().length < min){
        error += `${title} меньше ${min} символов. `;
      }

      // проверка на максимальное количество символов
      if (element.value.trim().length > max){
        error += `${title} больше ${max} символов. `;
      }

    }

    /* 2 Проверка спецсимволов : проверяем есть ли у элемента атрибут data-symbol */
    if (element.dataset.symbol){

      // только буквы и нижние подчеркивания без пробелов
      let re = /[^A-Za-zа-яА-ЯёЁ_]/ig;

      // если search возвращает не -1 , значит есть запрещенный символ
      if (element.value.search(re) !== -1){

        error += `${title} содержит запрещенные символы. `;

      }

    }

    /* 3 Проверка на правильный емаил : проверяем есть ли у элемента атрибут data-email */
    if (element.dataset.email){

      // если значения не содержит символа @
      if (!element.value.includes("@")){

        error += `${title} должена содержать символ @. `;

      }

    }

    /* 4 Совпадений паролей : проверяем есть ли у элемента атрибут data-comparisons */
    if (element.dataset.comparisons){

      // если массив для сравнения не передали в функцию
      if(array === null){

        console.info(new Error('Передайте массив для сравнения паролей'));

      }
      // иначи
      else {

        // проверяем есть ли в переданном массиве елемент с атрибутом data-parent, если есть возвращаем в переменую newPasswordOne
        let newPasswordOne = Array.from(array).find(function (element){
          if (element.dataset.parent)
            return true;
        });

        // если newPasswordOne неопределено
        if(newPasswordOne === undefined){

          console.info(new Error('В массиве нет совпадений'));

        }
        // иначи
        else {

          // спавниваем элемент с атрибутом data-comparisons с элементом с атрибутом data-parent
          if (element.value !== newPasswordOne.value){

            error += `${title} не совпадает. `;

          }

        }

      }

    }

    // если в переменной что то есть
    if(error){

      // создаем элемент
      let createElement = document.createElement("span");

      // добавляем класс
      createElement.classList.add("form__error");

      // ложим в элемент текст ошибки
      createElement.innerText = error;

      // находим родителя с классом form__element и с конец добавляем узел span
      element.closest(".form__element").appendChild(createElement);

    }

    // возврящаем переменную error
    return error;

  }

  // валидирует на проверку радиокнопок и чекбоксов на активное состояния
  function validationRadioAndCheckbox(element,title){

    // поздаем пустую переменную
    let error = '';

    // если это группа элементов
    if(element.length){

      // если в свойстве value у группы не чего нет
      if(!element.value){

        error += `${title}. `;

      }

    }
    // иначе если свойства length у элемента не существует , значит 1 элемент
    else {

      // если элемент не отмечен
      if(!element.checked){

        error += `${title}. `;

      }

    }

    // если в переменной что то есть
    if(error){

      // создаем элемент
      let createElement = document.createElement("span");

      // добавляем класс
      createElement.classList.add("form__error");

      // ложим в элемент текст ошибки
      createElement.innerText = error;

      // если это группа элементов
      if(element.length){

        element[0].closest(".form__element").appendChild(createElement);

      }
      // если это один элемент
      else {

        element.closest(".form__element").appendChild(createElement);

      }

    }

    // возврящаем переменную error
    return error;

  }

  /* ============ События =============*/

  // проходимся по всем формам
  listForms.forEach(element => {

    // вешаем события submit на каждую форму
    element.addEventListener("submit", function (evt){

      // evt.preventDefault();

      // вытаскиваем имя формы из атрибута data-name в переменную
      let formName = element.dataset.name;

      // создаем пустую переменную
      let result = '';

      // выбираем все элементы с классом form__error проходимся по нимм в цикле и удаляем
      element.querySelectorAll(".form__error").forEach(element => {
        element.remove();
      });

      // определяем переключатель
      switch(formName) {

        // если в formName равна значению enter
        case "enter":

          let enterName = element.elements.namedItem("name");
          let enterPassword = element.elements.namedItem("password");

          result += validation(enterName,"Логин",3,20);

          result += validation(enterPassword,"Пароль",3,20);

          // если в переменной что то есть отменяем отправку формы
          if (result){

            evt.preventDefault();

          }

          break;

        // если в formName равна значению registration
        case "registration":

          let registrationName = element.elements.namedItem("name");
          let registrationFloor = element.elements.namedItem("floor");
          let registrationPassword = element.elements.namedItem("password");
          let registrationRepeatPassword = element.elements.namedItem("repeat-password");
          let registrationEmail = element.elements.namedItem("email");
          let registrationRegulations = element.elements.namedItem("regulations");

          result += validation(registrationName,"Имя тренера",3,20);

          result += validationRadioAndCheckbox(registrationFloor,"Выберите пол тренера");

          result += validation(registrationPassword,"Пароль",3,20);

          result += validation(registrationRepeatPassword,"Пароль",3,20, element.elements);

          result += validation(registrationEmail,"Электронная почта",3,30);

          result += validationRadioAndCheckbox(registrationRegulations,"Примите пользовательские соглашения");

          // если в переменной что то есть отменяем отправку формы
          if (result){

            evt.preventDefault();

          }

          break;

        // если в formName равна значению recovery
        case "recovery":

          let recoveryEmail = element.elements.namedItem("email");

          result += validation(recoveryEmail,"Электронная почта",3,30);

          // если в переменной что то есть отменяем отправку формы
          if (result){

            evt.preventDefault();

          }

          break;

        // если в formName равна значению newPassword
        case "newPassword":

          let newPasswordOne = element.elements.namedItem("password");
          let newPasswordTwo = element.elements.namedItem("repeat-password");

          result += validation(newPasswordOne,"Пароль",3,20);

          result += validation(newPasswordTwo,"Пароль",3,20, element.elements);

          // если в переменной что то есть отменяем отправку формы
          if (result){

            evt.preventDefault();

          }

          break;

        // если в formName не равна не чему
        default:
          console.log("Не существует валидации под эту форму");
      }


    });

  });

}
catch(err){
  console.info("Form: " + err.message);
}
/* ================================================================================ */
/* =========================== ПРОКРУТИТЬ ВВЕРХ =================================== */
/* ================================================================================ */


if(document.querySelector(".js-up") !== null){

  /* ============ Переменные =============*/

  const scrollToHandler = document.querySelector(".js-up");
  const scrollToHandlerBtn = scrollToHandler.querySelector(".up__button");
  const scrollNews = document.querySelector(".js-news");

  /* ============ Переменные =============*/


  /* ============ События =============*/

  window.addEventListener("scroll", function (){

    const box = scrollNews.getBoundingClientRect().top + pageYOffset;

    if(pageYOffset >= box){

      if(!scrollToHandler.classList.contains("up--active")){
        scrollToHandler.classList.add("up--active");
        scrollToHandler.classList.add("up--show");
      }
    }else{

      if(scrollToHandler.classList.contains("up--active")){
        scrollToHandler.classList.remove("up--show");

        setTimeout(function (){
          scrollToHandler.classList.remove("up--active");
        },400);
      }
    }
  });

  scrollToHandlerBtn.addEventListener("click",function (){

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

  });

  /* ============ События =============*/

}
/* =======================================================
*
*  ОТВЕЧАЕТ ЗА ОТВЕТЫ ОТ СЕРВЕРА НА ЗАПРОСЫ ПОЛЬЗОВАТЕЛЯ
*
* ====================================================== */

try {

  /* ============ Переменные =============*/

  const answer = document.querySelectorAll(".success, .error, .inform");

  /* ============ События =============*/

  // проходимся по всем ответам в цикле
  answer.forEach(function (element) {

    // каждому ответу назначаем события клик
    element.addEventListener("click", function (evt) {

      // отменяем событие по умолчанию
      evt.preventDefault();

      // даем клас hide
      this.classList.add("hide");

      // удаляем ответ через 300 милисекунд
      setTimeout(() => this.remove(),300);

    })
  });
}
catch(err){
  console.info("Answer: " + err.message);
}
/* ======================================
*
*  АНИМАЦИЯ ПОКМОНОВ В БЛОКЕ INFO и OFFER
*
* ===================================== */

function getRandomInRange(min, max, parent) {

  let number = Math.floor(Math.random() * (max - min + 1)) + min;

  if(number !== parent){

    return number;

  }else {

    return getRandomInRange(min, max, parent);

  }

}

if(window.innerWidth >= 992){

  try {

    const flag = document.querySelector("[data-animations]");

    if(flag !== null){

      // изначальный покемон
      let pokemonId = !isNaN(+flag.dataset.animations) && +flag.dataset.animations < 4 ? flag.dataset.animations : 0;

      const arrPokemon = [
        ["info__inner--charizard","offer__inner--charizard"],
        ["info__inner--incineroar","offer__inner--incineroar"],
        ["info__inner--mewtwo","offer__inner--mewtwo"],
        ["info__inner--ivysaur","offer__inner--ivysaur"]
      ];

      const infoPokemon = document.querySelector(".js-info__inner");

      setInterval(function (){

        // придыдущий покемон
        let previousID = pokemonId;

        // новый выбраный покемон
        pokemonId =  getRandomInRange(0, 3, pokemonId);

        flag.classList.add("offer__inner--hidden");

        if(infoPokemon !== null){

          infoPokemon.classList.add("info__inner--hidden");

        }

        setTimeout(function () {

          flag.classList.remove(arrPokemon[previousID][1]);

          flag.classList.add(arrPokemon[pokemonId][1]);
          flag.classList.remove("offer__inner--hidden");

          if(infoPokemon !== null){

            infoPokemon.classList.remove(arrPokemon[previousID][0]);

            infoPokemon.classList.add(arrPokemon[pokemonId][0]);
            infoPokemon.classList.remove("info__inner--hidden");

          }

        },800);

      },10000);

    }

  }
  catch(err){
    console.info("Animation pokemons: " + err.message);
  }

}